<template>
  <el-tabs class="tabs">
    <!-- <el-tab-pane :style="GetStyle">
      <span slot="label"><i class="el-icon-reading"></i>&nbsp;操作手册</span>
      <div class="help">
        <el-button type="text" @click="download">下载</el-button>
        <div id="wordView" v-html="wordText"/>
      </div>
    </el-tab-pane> -->
    <el-tab-pane label="" :style="GetStyle">
      <span slot="label"><i class="el-icon-video-camera"></i>&nbsp;帮助视频</span>
      <el-header class="header_class">
        <el-radio-group v-model="videoType" style="margin: 10px auto;">
          <el-radio-button label="All" @click.native="handlQueryByType('All')">全部</el-radio-button>
          <el-radio-button v-for="item in types" :key="item.Id" :label="item.DicValue" @click.native="handlQueryByType(item.DicName)">{{ item.DicName }}</el-radio-button>
        </el-radio-group>
        <el-button type="text" style="float: right; font-size:16px;" @click="download">下载操作手册</el-button>
      </el-header>
      <div style="display: flex;flex-wrap: wrap">
        <el-card class="video" :body-style="{ padding:'0' }" v-for="item in data" :key="item.Id" @click.native="openPlayer(item)">
          <p class="vtitle"><span>{{ item.V_Name }}</span></p>
          <div class="player">
            <el-image :src="require('@/assets/helpvideoCover.jpg')"></el-image>
          </div>
          <div style="padding: 5px;">

            <p class="vdesc"><span>描述：{{ item.V_Description }}</span></p>
            <div class="bottom clearfix">
              <time class="time">{{ timeFormat(item.UpdateTime) }}</time>
            </div>
          </div>
        </el-card>
      </div>
    </el-tab-pane>
    <PlayVideo ref="playVideo"></PlayVideo>
  </el-tabs>
</template>

<script>
import mammoth from "mammoth";
import moment from 'moment'
import localForage from "localforage"; // indexDB 存储
import {download} from "@/utils/Tools";
import PlayVideo from "@/views/help/PlayVideo";

export default {
  components: {
    PlayVideo
  },
  data() {
    return {
      data: [],
      types: [],
      videoType: 'All',
      sorter: {field: 'Sort', order: 'asc'},
      queryParam: [{Status:0}],
      wordText: "",
      wordURL: "/Base_Manage/Base_User/DownloadHelpWord", //文件地址
    };
  },
  created() {
    localForage.getItem("help").then((res) => {
      if (res) {
        mammoth
            .convertToHtml({arrayBuffer: new Uint8Array(res)})
            .then((resultObject) => {
              this.$nextTick(() => {
                this.wordText = resultObject.value;
              });
            });
      } else {
        this.getWordText();
      }
    });
  },
  computed: {
    GetStyle() {
      return {
        height: (document.body.clientHeight - 155) + 'px',
        overflow: 'auto',
        backgroundColor: '#fff'
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getVideoType()
      this.getHelpVideo()
    })
  },
  methods: {
    download() {
      download(this.$baseUrl + '/Base_Manage/Base_User/GetHelpPDF', '码上签电子回执操作手册.pdf')
    },
    getWordText() {
      const xhr = new XMLHttpRequest();
      xhr.open("get", this.$baseUrl + this.wordURL, true);
      xhr.responseType = "arraybuffer";
      xhr.onload = () => {
        if (xhr.status == 200) {
          console.log(xhr.response);
          localForage.setItem("help", xhr.response);
          mammoth
              .convertToHtml({arrayBuffer: new Uint8Array(xhr.response)})
              .then((resultObject) => {
                this.$nextTick(() => {
                  this.wordText = resultObject.value;
                });
              });
        }
      };
      xhr.send();
    },
    getHelpVideo() {
      this.$http.post('/Base_BasicData/base_videohelper/GetDataListByMoreCondition', {
        SortField: this.sorter.field || 'Id',
        SortType: this.sorter.order,
        Search: this.queryParam,
      }).then(resJson => {
        this.data = resJson.Data
      })
    },
    getVideoType() {
      this.$http.post('/Base_BasicData/Base_DictionaryMain/GetTheDataByDicKey', {
        Condition: 'DicKey',
        Keyword: 'V_Type'
      }).then(resJson => {
        if (resJson.Success) {
          this.types = resJson.Data.DictionaryDetailsList
        }
      })
    },
    handlQueryByType(type) {
      if (type === 'All') {
        this.queryParam = [{Status:0}]
      } else {
        this.queryParam = [{Status:0},{condition: 'V_Type', keyword: type}]
      }
      this.getHelpVideo()
    },
    openPlayer(item) {
      this.$refs.playVideo.openForm(item.V_FileUrl, '视频预览')
    },
    timeFormat (val) { // 时间格式化函数
      return moment(val).format('YYYY-MM-DD HH:mm')
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ p img {
  width: 150px;
}

/deep/ p {
  color: #000;
  font-size: 20px;
}

.help {
  padding: 20px;
  width: 100%;
  overflow: hidden;

  .el-button {
    background-color: #d50700;
    border: 1px solid;
    width: 7%;
    height: 7%;
    border-radius: 30px;
    color: #fff;
    font-size: 17px;
    font-weight: bolder;
    padding: 16px;
    margin-left: 87%;
  }
}
.header_class {
  background: linear-gradient(80deg,rgba(255, 255, 255, 0.8),rgba(0, 130, 255, 0.2),rgba(0, 130, 255, 0.15) ,rgba(255, 255, 200, 0.1),rgba(0, 130, 255, 0.15), rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(8px);
  text-align: left;
  z-index: 999;
  top: 0;
  position: sticky;
  width: 100%;
  height: 60px !important;
  line-height: 60px !important;
  margin: 0 auto;
  box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
}
.video {
  width: 300px;
  height: 280px;
  margin: 10px 15px;
  border: 1px solid #fff;
  border-radius: 10px;
  position: relative;

  .vtitle{
    margin: 10px auto;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
  }
  .vdesc{
    font-size: 12px;
    //text-indent: 2em;
  }
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  position: absolute;
  right: 10px;
  bottom: 10px;
  line-height: 12px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
